export default [
    // {
    //     name: 'backgroundSound',
    //     type: 'audio',
    //     path: 'sounds/background.mp3'
    // },
    // {
    //     name: 'predatorModel',
    //     type: 'gltfModel',
    //     path: 'models/predator.glb'
    // },
    {
        name: 'displacementTexture',
        type: 'texture',
        path: 'textures/displacement.jpg'
    }
]
